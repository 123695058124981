import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const Form = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: 610,
            height: 500,
          },
        }}
      >
        <Paper elevation={3}>
          <div className="m-4">
            <h4 className="m-4 ">Change Password</h4>
            <div className="p-3 bg-white bg-opacity-10 border   rounded-0 m-4">
              <form className="m-4">
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    Enter Current Password
                  </label>
                  <input
                    type="password"
                    class="form-control border  rounded-0"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    Enter New Password
                  </label>
                  <input
                    type="password"
                    class="form-control border  rounded-0"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword2" class="form-label">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    class="form-control border  rounded-0"
                    id="exampleInputPassword2"
                  />
                </div>

                <button type="submit" class="btn btn-success rounded-0 ">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default Form;
