import { Box, Paper } from "@mui/material";
import React from "react";

const ContainerFirst = () => {
  return (
    <div>
      <Box
        className="col"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: 127.5,
            height: 127.5,
          },
        }}
      >
        {firstContainer.map((value, id) => (
          <Paper style={{ background: value.background }}>
            <center>
              <img
                src={value.img}
                alt=""
                className="row py-2"
                style={{ height: "100px" }}
              />
              <h5 style={{ color: value.color }}>{value.title}</h5>
            </center>
          </Paper>
        ))}
      </Box>
    </div>
  );
};

export default ContainerFirst;

const firstContainer = [
  {
    id: 1,
    title: "Bookings",
    img: "https://getproteck.com/images/Xpertzguru/orders_svg.svg",
    background: "#fff5f8",
    color: "",
  },
  {
    id: 2,
    title: "Total Rooms",
    img: "https://getproteck.com/images/Xpertzguru/products_svg.svg",
    background: "#f1faff",
    color: "",
  },
  {
    id: 3,
    title: "Settings",
    img: "https://getproteck.com/images/Xpertzguru/settings_svg.svg",
    background: "#f1faff",
    color: "",
  },
  {
    id: 4,
    title: "Ratings",
    img: "https://getproteck.com/images/Xpertzguru/review_svg.svg",
    background: "#fff8dd",
    color: "",
  },
];
