import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Table from "../Table/Table";

const ContainerSecond = () => {
  return (
    <div>
      <div className="row">
        <h5 className="text-danger  p-2">Hotel Bookings</h5>
        <div className="col-3 m-2 border rounded-4 bg-light">
          <Box
            type="Submit"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 110,
                height: 110,
              },
            }}
          >
            {secondContainer.map((value, id) => (
              <Paper elevation={2} className=" border border-info rounded-3">
                <center>
                  <img
                    src={value.img}
                    alt=""
                    className="row py-2"
                    style={{ height: "80px" }}
                  />
                  <h6 style={{ color: value.color }}>{value.title}</h6>
                </center>
              </Paper>
            ))}
            {/* <Paper elevation={2} className="border border-warning rounded-3">
              <center>
                <img
                  src="https://img.icons8.com/color/512/user.png"
                  alt=""
                  className="row py-2"
                  style={{ height: "80px" }}
                />
                <h6 className="text-warning">Customers</h6>
              </center>
            </Paper>
            <Paper elevation={2} className="border border-success rounded-3">
              <center>
                <img
                  src="	https://img.icons8.com/color/2x/home.png"
                  alt=""
                  className="row py-2"
                  style={{ height: "80px" }}
                />
                <h6 className="text-success">Rooms Types</h6>
              </center>
            </Paper>
            <Paper elevation={2} className="border border-danger rounded-3">
              <center>
                <img
                  src="https://img.icons8.com/color/2x/get-up.png"
                  alt=""
                  className="row py-2"
                  style={{ height: "80px" }}
                />
                <h6 className="text-danger">Facilities</h6>
              </center>
            </Paper> */}
          </Box>
        </div>

        <div className="col m-2 border rounded-4 bg-light">
          <Table
            tableData={bookingData}
            tableHeading={bookingHeaders}
            hideHeaderBtns={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ContainerSecond;

const bookingData = [
  {
    rolNo: 101,
    name: "Mark",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
  },
  {
    rolNo: 102,
    name: "Ruth",
    checkIn: "5-2-2022",
    checkOut: "20-2-2022",
  },
  {
    rolNo: 103,
    name: "Mark",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
  },
  {
    rolNo: 104,
    name: "Ruth",
    checkIn: "5-2-2022",
    checkOut: "20-2-2022",
  },
  {
    rolNo: 105,
    name: "Mark",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
  },
  {
    rolNo: 106,
    name: "Ruth",
    checkIn: "5-2-2022",
    checkOut: "20-2-2022",
  },
  {
    rolNo: 107,
    name: "Mark",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
  },
  {
    rolNo: 108,
    name: "Ruth",
    checkIn: "5-2-2022",
    checkOut: "20-2-2022",
  },
  {
    rolNo: 109,
    name: "Mark",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
  },
];

const bookingHeaders = [
  { id: 2, heading: "Roll No.", key: "rolNo" },
  { id: 1, heading: "Name", key: "name", scope: "row" },
  { id: 3, heading: "Check In", key: "checkIn" },
  { id: 4, heading: "CheckOut", key: "checkOut" },
];

const secondContainer = [
  {
    id: 1,
    title: "Rooms",
    img: "https://img.icons8.com/color/2x/home.png",
    color: "#0dcaf0",
  },
  {
    id: 2,
    title: "Customers",
    img: "https://img.icons8.com/color/512/user.png",
    color: "#ffc107",
  },
  {
    id: 3,
    title: "Room Types",
    img: "https://img.icons8.com/color/2x/home.png",
    color: "#dc3545",
  },
  {
    id: 4,
    title: "Facilities",
    img: "https://img.icons8.com/color/2x/get-up.png",
    color: "#198754",
  },
];
