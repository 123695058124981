import React from "react";
import UserTable from "../layouts/Table/UserTable";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "../layouts/Table/Table";
import AddIcon from "@mui/icons-material/Add";

const VoucherManagement = () => {
  return (
    <div>
      <Table
        tableButtons={tableButtons}
        tableHeading={voucherHeading}
        tableData={voucherData}
        title="Voucher Management"
      />
    </div>
  );
};

export default VoucherManagement;

const tableButtons = [
  // { id: 1, title: "Add New", icon: <AddIcon />, color: "#0dcaf0" },
  { id: 2, title: "Filter", icon: <FilterAltIcon />, color: "#ffc107" },
  { id: 3, title: "Export", icon: <FileUploadIcon />, color: "#198754" },
];

const voucherHeading = [
  { id: 1, heading: "Voucher Id", key: "voucherId", scope: "row" },
  { id: 2, heading: "User Name", key: "userName" },
  {
    id: 3,
    heading: "User Id",
    key: "userId",
  },
  {
    id: 4,
    heading: "Email Id",
    key: "emailId",
  },
  {
    id: 5,
    heading: "Contact Number",
    key: "contactNumber",
  },
];

const voucherData = [
  {
    id: 1,
    voucherId: "1234",
    userName: "Mark Adams",
    userId: "13624454",
    emailId: "abc@gmail.com",
    contactNumber: " +91 1234567890",
  },
  {
    id: 2,
    voucherId: "1234",
    userName: "Mark Adams",
    userId: "13624454",
    emailId: "abc@gmail.com",
    contactNumber: " +91 1234567890",
  },
  {
    id: 3,
    voucherId: "1234",
    userName: "Mark Adams",
    userId: "13624454",
    emailId: "abc@gmail.com",
    contactNumber: " +91 1234567890",
  },
];
