import React from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const UserTable = ({ userTableButtons, userData, userHeading, title }) => {
  return (
    <>
      <div className="bg-light p-4">
        <h3 className="text-danger pb-4">{title}</h3>
        <div className="row  m-4 p-4 d-flex justify-content-around">
          <div className="col-6">
            <Search className="bg-white">
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </div>
          <div className="col d-flex justify-content-around">
            {userTableButtons.map((value, id) => (
              <Button
                variant="contained"
                startIcon={value.icon}
                style={{ background: value.color }}
              >
                {value.title}
              </Button>
            ))}
          </div>
        </div>
        <table class="table table-striped bg-white">
          <thead>
            <tr>
              {userHeading?.map((val, i) => (
                <th scope="col" key={i}>
                  {val?.heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {userData.map((value, id) => (
              <tr key={id}>
                {userHeading?.map((header, i) => (
                  <td key={i} scope={header?.scope || ""}>
                    {header?.icon ? header?.icon : value?.[header?.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserTable;
