import React from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Table from "../layouts/Table/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const ReviewManagement = () => {
  return (
    <>
      <Table
        tableButtons={tableButtons}
        tableHeading={reviewHeading}
        tableData={reviewData}
        title="Review Management"
        hideHeaderBtns={true}
      />
    </>
  );
};

export default ReviewManagement;

const tableButtons = [
  { id: 1, title: "Add User", icon: <FilterAltIcon />, color: "#0dcaf0" },
  { id: 2, title: "Filter", icon: <FilterAltIcon />, color: "#ffc107" },
  { id: 3, title: "Export", icon: <FileUploadIcon />, color: "#198754" },
];

const reviewHeading = [
  { id: 1, heading: "User Id", key: "userId", scope: "row" },
  // { id: 2, heading: "Name", key: "userName" },
  // { id: 3, heading: "Email Id", key: "emailId" },
  { id: 4, heading: "Review", key: "review" },
  {
    id: 5,
    heading: "Delete",
    key: "delete",
    icon: <DeleteOutlineOutlinedIcon />,
  },
];

const reviewData = [
  {
    id: 1,
    userId: "13624454",
    userName: "Mark Adams",
    emailId: "abc@gmail.com",
    review:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.  ",
  },
  {
    id: 2,
    userId: "13624454",
    userName: "Mark Adams",
    emailId: "abc@gmail.com",
    review:
      "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.  ",
  },
  {
    id: 3,
    userId: "13624454",
    userName: "Mark Adams",
    emailId: "abc@gmail.com",
    review:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.  ",
  },
  {
    id: 4,
    userId: "13624454",
    userName: "Mark Adams",
    emailId: "abc@gmail.com",
    review:
      "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.  ",
  },
];
