import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ExcelExport from "./JSX/components/ExcelExport";
import Home from "./JSX/components/Home";
import UserManagement from "./JSX/components/UserManagement";
import Header from "./JSX/layouts/nav/Header";
import Sidebar from "./JSX/layouts/nav/Sidebar";
import Login from "./JSX/pages/Login";

const App = () => {
  return (
    <>
      <BrowserRouter>
        {/* <Header /> */}
        <Sidebar />
        {/* <Routes>
          <Route path="/" element={<Login />} />
        </Routes> */}
      </BrowserRouter>
    </>
  );
};

export default App;
