import React from "react";
import UserTable from "../layouts/Table/UserTable";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";

const UserManagement = () => {
  return (
    <div>
      <UserTable
        userTableButtons={userTableButtons}
        userHeading={userHeading}
        userData={userData}
        title="User Management"
      />
    </div>
  );
};

export default UserManagement;

const userHeading = [
  { id: 1, heading: "User Id", key: "userId", scope: "row" },
  { id: 2, heading: "User Name", key: "userName" },
  { id: 3, heading: "Check In", key: "checkIn" },
  { id: 4, heading: "Check Out", key: "checkOut" },
  { id: 5, heading: "Contact Number", key: "contactNumber" },
  { id: 6, heading: "Email Id", key: "emailId" },
  {
    id: 7,
    heading: "Edit",
    key: "edit",
    icon: (
      <BorderColorOutlinedIcon
        onClick={() => alert("hi there")}
        className="text-dark"
      />
    ),
  },
  {
    id: 8,
    heading: "Delete",
    key: "delete",
    icon: <DeleteOutlineOutlinedIcon />,
  },
];

const userData = [
  {
    id: "1",
    userId: "13624454",
    userName: "Mark Adams",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
    contactNumber: "+91 1234567890",
    emailId: "abc@gmail.com",
  },
  {
    id: "2",
    userId: "27652447",
    userName: "Jacob chain",
    checkIn: "2-4-2021",
    checkOut: "3-4-2021",
    contactNumber: "+91 1234567890",
    emailId: "abc@gmail.com",
  },
  {
    id: "3",
    userId: "36783988",
    userName: "	Larry ley",
    checkIn: "8-12-2020",
    checkOut: "9-12-2020",
    contactNumber: "+91 1234567890",
    emailId: "abc@gmail.com",
  },
  {
    id: "4",
    userId: "43624454",
    userName: "Lark Adams",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
    contactNumber: "+91 1234567890",
    emailId: "abc@gmail.com",
  },
  {
    id: "5",
    userId: "57652447",
    userName: "Jacob chain",
    checkIn: "2-4-2021",
    checkOut: "3-4-2021",
    contactNumber: "+91 1234567890",
    emailId: "abc@gmail.com",
  },
  {
    id: "6",
    userId: "66783988",
    userName: "	Larry ley",
    checkIn: "8-12-2020",
    checkOut: "9-12-2020",
    contactNumber: "+91 1234567890",
    emailId: "abc@gmail.com",
  },
];

const userTableButtons = [
  // { id: 1, title: "Add User", icon: <PersonAddAltIcon />, color: "#0dcaf0" },
  { id: 2, title: "Filter", icon: <FilterAltIcon />, color: "#ffc107" },
  { id: 3, title: "Export", icon: <FileUploadIcon />, color: "#198754" },
];
