import React from "react";
import Form from "../layouts/Form/Form";

const Setting = () => {
  return (
    <>
      <div className="bg-light p-4">
        <h3 className="text-danger">Manage Settings</h3>
        <Form />
      </div>
    </>
  );
};

export default Setting;
