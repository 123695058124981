import * as React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Header from "../nav/Header";

// Added icons
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import MoneyOutlinedIcon from "@mui/icons-material/MoneyOutlined";
import Home from "../../components/Home";
import UserManagement from "../../components/UserManagement";
import RoomBookings from "../../components/RoomBookings";
import RoomManagement from "../../components/RoomManagement";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import CategoryManagement from "../../components/CategoryManagement";
import VoucherManagement from "../../components/VoucherManagement";
import BookingReports from "../../components/BookingReports";
import ReviewManagement from "../../components/ReviewManagement";
import Setting from "../../components/Setting";
import AddNewForm from "../Form/AddNewForm";
import Login from "../../pages/Login";

const drawerWidth = 280;

export default function Sidebar() {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAuth(event.target.checked);
    setAge(event.target.value);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* <Header /> */}
      <AppBar
        className="d-flex justify-content-end text-dark rounded-xl rounded-bottom"
        style={{ background: "white" }}
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1 }}
            // style={{ color: "yellow" }}
          >
            Ram's Nest
          </Typography>
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge badgeContent={4} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />

        <List>
          {[
            { title: "Dashboard", link: "/" },
            { title: "User Management", link: "/user" },
            { title: "Room Management", link: "/roomManagement" },
            { title: "Room Bookings", link: "/room" },
            { title: "Category Management", link: "/category" },
            { title: "Voucher Management", link: "/voucher" },
            { title: "Booking Reports", link: "/bookingReports" },
            { title: "Booking Reviews", link: "/reviews" },
            { title: "Settings", link: "/setting" },
            { title: "Logout", link: "/" },
          ].map((text, index) => (
            <Link to={text.link} style={{ textDecoration: "none" }}>
              <ListItem
                key={text}
                disablePadding
                className=" my-1 text-dark"
                variant="h6"
              >
                <ListItemButton className=" rounded-pill mx-1">
                  <ListItemIcon className="text-danger">
                    {index === 0 && <HomeOutlinedIcon />}
                    {index === 1 && <PeopleOutlineOutlinedIcon />}
                    {index === 2 && <ViewInArOutlinedIcon />}
                    {index === 3 && <CasesOutlinedIcon />}
                    {index === 4 && <AutoAwesomeMotionOutlinedIcon />}
                    {index === 5 && <MoneyOutlinedIcon />}
                    {index === 6 && <ReportProblemOutlinedIcon />}
                    {index === 7 && <ReviewsOutlinedIcon />}
                    {index === 8 && <SettingsSuggestIcon />}
                    {index === 9 && <LogoutIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text.title} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgColor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/user" element={<UserManagement />} />
          <Route path="/room" element={<RoomBookings />} />
          <Route path="/roomManagement" element={<RoomManagement />} />
          <Route path="/category" element={<CategoryManagement />} />
          <Route path="/voucher" element={<VoucherManagement />} />
          <Route path="/bookingReports" element={<BookingReports />} />
          <Route path="/reviews" element={<ReviewManagement />} />
          <Route path="/setting" element={<Setting />} />
          {/* <Route path="/login" element={<AddNewForm />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
        </Routes>
      </Box>
    </Box>
  );
}
