import React from "react";
import Reports from "../layouts/Reports/Reports";
import UserTable from "../layouts/Table/UserTable";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Table from "../layouts/Table/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const BookingReports = () => {
  return (
    <div>
      <Reports />
      <Table
        tableHeading={tableHeadingData}
        tableData={roomBookingData}
        hideHeaderBtns={true}
      />
    </div>
  );
};

export default BookingReports;

const tableHeadingData = [
  {
    id: 1,
    heading: "Booking Id",
    key: "bookingId",
  },
  {
    id: 2,
    heading: "Customer Name ",
    key: "customerName",
  },
  {
    id: 3,
    heading: "Check In",
    key: "checkIn",
  },
  {
    id: 4,
    heading: "Check Out",
    key: "checkOut",
  },
  {
    id: 5,
    heading: "Price",
    key: "price",
  },
  {
    id: 6,
    heading: "Room Type",
    key: "roomType",
  },
];

const roomBookingData = [
  {
    id: "1",
    bookingId: "13624454",
    customerName: "Mark Adams",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
    price: "5000/-",
    roomType: "A/C Room",
  },
  {
    id: "2",
    bookingId: "27652447",
    customerName: "Jacob chain",
    checkIn: "2-4-2021",
    checkOut: "3-4-2021",
    price: "3000/-",
    roomType: " Non A/C Room",
  },
  {
    id: "3",
    bookingId: "36783988",
    customerName: "	Larry ley",
    checkIn: "8-12-2020",
    checkOut: "9-12-2020",
    price: "2000/-",
    roomType: "Sample",
  },
  {
    id: "4",
    bookingId: "43624454",
    customerName: "Lark Adams",
    checkIn: "1-2-2022",
    checkOut: "2-2-2022",
    price: "5000/-",
    roomType: "A/C Room",
  },
  {
    id: "5",
    bookingId: "57652447",
    customerName: "Jacob chain",
    checkIn: "2-4-2021",
    checkOut: "3-4-2021",
    price: "3000/-",
    roomType: " Non A/C Room",
  },
  {
    id: "6",
    bookingId: "66783988",
    customerName: "	Larry ley",
    checkIn: "8-12-2020",
    checkOut: "9-12-2020",
    price: "2000/-",
    roomType: "Sample",
  },
];
