import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const Reports = () => {
  return (
    <div className="p-2 my-2 bg-light">
      <h3 className="text-success">Booking Reports</h3>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 2,
            width: 190,
            height: 150,
            transition: " transform .2s",
            "&:hover": {
              transform: "scale(1.1)",
            },
          },
        }}
      >
        {reportsData.map((value, id) => (
          <Paper elevation={3}>
            <Paper
              className="rounded-0"
              elevation={5}
              sx={{ width: 180, height: 140 }}
              style={{ backgroundColor: value.color }}
            >
              <h5
                className="text-white py-4 text-center"
                style={{ fontWeight: "600", lineHeight: "45px" }}
              >
                <span>{value.number}</span>
                <br />
                {value.title}
              </h5>
            </Paper>
          </Paper>
        ))}
      </Box>
    </div>
  );
};

export default Reports;

const reportsData = [
  {
    id: 1,
    number: 2121,
    title: "Total Bookings",
    color: "#0dcaf0",
  },
  {
    id: 2,
    number: 212,
    title: "Total Customers",
    color: "#ffc107",
  },
  {
    id: 3,
    number: 21,
    title: " Booking Canceled",
    color: "#dc3545",
  },
  {
    id: 4,
    number: 12121,
    title: "Total  Revenue",
    color: "#198754",
  },
];
